import {useContext, useEffect, useState} from 'react'
import {ANALYTICS_ENABLED} from '../../../lib/analytics/appAnalytics'
import {trackViewedPR} from './trackViewedPR'
import {PRComponentContext} from '../../press-release/PRComponent'

export default function PRAnalytics() {
  const {pressRelease} = useContext(PRComponentContext)

  const [analyticsFired, setAnalyticsFired] = useState(false)

  useEffect(() => {
    if (ANALYTICS_ENABLED && !analyticsFired && pressRelease) {
      trackViewedPR({onSuccess: () => setAnalyticsFired(true), pressRelease})
    }
  }, [analyticsFired, pressRelease])

  return null
}
