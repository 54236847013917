import {mixpanel, trackGoogleEvent} from '../../../lib/analytics/appAnalytics'
import {Story} from '../../../lib/sanity/types'
import {getStoryUrl} from '../../story/utils'
import {setGAPageProperties} from '../utils'

interface AnalyticsFnOptions {
  onSuccess?: () => void
}
interface AnalyticsFnWithResourceOptions extends AnalyticsFnOptions {
  story: Story
}

export function trackViewedStory({
  onSuccess,
  story,
}: AnalyticsFnWithResourceOptions) {
  // Google Analytics
  trackGoogleEvent(
    'glance-track_custom_dimensions',
    {
      event_category: 'glance-viewed_story',
      page_location: getStoryUrl(story.dateSlug, story.slug.current),
      page_title: story.title,
    },
    {
      dimension18: story.category,
      dimension19: story.sponsor?.uuid,
      dimension20: story.organizations?.map((org) => org.name),
    },
  )
  setGAPageProperties(story)

  // Mixpanel
  mixpanel.track('Viewed Story', {
    'Story Category': story.category,
    'Story Slug': story.slug.current,
    'Story Tags': story.tags?.map((tag) => tag.name),
    'Story Title': story.title,
    'Story Author': story.authors?.map((author) => author.name),
    'Story Sponsor': story.sponsor?.uuid,
    'Story Organizations': story.organizations?.map((org) => org.name),
  })
  onSuccess?.()
}
