import React, {useContext, useEffect, useState} from 'react'
import {StoryComponentContext} from '../StoryComponent'
import {trpc} from '../../../utils/trpc'
import {AuthenticationContext} from '../../../lib/auth'
import {mixpanel} from '../../../lib/analytics/appAnalytics'
import {
  MIXPANEL_EVENT_RATED_CONTENT_QUALITY,
  MIXPANEL_PLATFORM,
} from '../../appAnalytics/mixpanel'

export const RatingWidget = () => {
  const {story} = useContext(StoryComponentContext)
  const {user, openAuthModal} = useContext(AuthenticationContext)
  const [success, setSuccess] = useState(false)
  const [visible, setVisible] = useState(true)
  const id = story._id

  const {mutate: logRating} = trpc.analytics.logRating.useMutation()
  const handleButtonClick = (value: number) => {
    if (!user) {
      openAuthModal()
    } else {
      try {
        logRating({sanityId: id, rating: value})
        sessionStorage.setItem(`${id}_rated`, 'true')
        mixpanel.track(MIXPANEL_EVENT_RATED_CONTENT_QUALITY, {
          'Page Slug': story.slug.current,
          Rating: value.toString(),
          Platform: MIXPANEL_PLATFORM,
        })
      } catch (e) {
        // continue regardless of error
      }
      // Show success state regardless of response
      setSuccess(true)
    }
  }

  // Check session storage on mount to see if the user has already rated the story
  useEffect(() => {
    const isRated = sessionStorage.getItem(`${id}_rated`) === 'true'
    setVisible(!isRated)
  }, [id])

  if (!visible) return null

  return (
    <div className="mt-4 card mb-lg-4">
      <div className="card-body">
        {success ? (
          <h3>
            Thanks for rating this content! Your feedback helps us improve.
          </h3>
        ) : (
          <div>
            <h3>How would you rate the quality of this content?</h3>
            <div className="btn-group w-100">
              {[...Array(10)].map((_, index) => (
                <button
                  key={index}
                  className="btn btn-outline-primary btn-sm py-lg-3"
                  onClick={() => handleButtonClick(index + 1)}
                >
                  {index + 1}
                </button>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
