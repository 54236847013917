import {useContext, useEffect, useState} from 'react'
import {ANALYTICS_ENABLED} from '../../../lib/analytics/appAnalytics'
import {StoryComponentContext} from '../../story/StoryComponent'
import {trackViewedStory} from './trackViewedStory'

export default function StoryAnalytics() {
  const {story} = useContext(StoryComponentContext)

  const [analyticsFired, setAnalyticsFired] = useState(false)

  useEffect(() => {
    if (ANALYTICS_ENABLED && !analyticsFired && story) {
      trackViewedStory({onSuccess: () => setAnalyticsFired(true), story})
    }
  }, [analyticsFired, story])

  return null
}
