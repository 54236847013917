import {mixpanel, trackGoogleEvent} from '../../../lib/analytics/appAnalytics'
import {PressRelease} from '../../../lib/sanity/types'
import {getPRUrl} from '../../press-release/utils'
import {setGAPageProperties} from '../utils'

interface AnalyticsFnOptions {
  onSuccess?: () => void
}
interface AnalyticsFnWithResourceOptions extends AnalyticsFnOptions {
  pressRelease: PressRelease
}

export function trackViewedPR({
  onSuccess,
  pressRelease,
}: AnalyticsFnWithResourceOptions) {
  // Google Analytics
  trackGoogleEvent(
    'glance-track_custom_dimensions',
    {
      event_category: 'glance-viewed_pressRelease',
      page_location: getPRUrl(pressRelease.slug.current),
      page_title: pressRelease.title,
    },
    {
      dimension18: pressRelease.category,
      dimension20: pressRelease.organizations?.map((org) => org.name),
    },
  )
  setGAPageProperties(pressRelease)

  // Mixpanel
  mixpanel.track('Viewed Press Release', {
    'Press Release Category': pressRelease.category,
    'Press Release Slug': pressRelease.slug.current,
    'Press Release Tags': pressRelease.tags?.map((tag) => tag.name),
    'Press Release Title': pressRelease.title,
    'Press Release Author': pressRelease.authors?.map((author) => author.name),
    'Press Release Organizations': pressRelease.organizations?.map(
      (org) => org.name,
    ),
  })
  onSuccess?.()
}
